import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Image from "components/ui/image"
import Clients from "components/banners/clients"
import Button from "components/ui/button"

const AboutPage = () => {
  const images = useStaticQuery(graphql`
  query {
    banner: file(relativePath: { eq: "about/banner.png" }) {
      ...imageQuery
    }
  }
  `)
  return (
    <p>Page under development</p>
  // <Layout path="/about/">
  //   <SEO
  //     title="Home"
  //     description="This is the description of the index page right here now"
  //     keywords={[`Rishaba`, `Butterfly`, `Home Appliances`, `Industrial Design`, `Engineering`, `Mold Design`, `Manufacturing`]}
  //     ogUrl="/about"
  //   />
  //   <Image image={images.banner} alt="About us Banner" />
  //   <div className="container max-width-70-lg">
  //     <h1 className="d-flex row justify-content-center font-size-66-lg font-bold">About us</h1>
  //     <h3 className="text-align-center-sm text-primary font-size-14 my-40 font-bold">BUTTERFLY GANDHIMATHI APPLIANCES LTD </h3>
  //     <p className="text-align-center-sm text-secondary font-size-14 line-height-30">
  //       At a time when brass was still considered a wonder metal, Late V. Murugesa
  //       Chettiar, found a golden opportunity in Stainless Steel. Thus, was born a
  //       pioneer. And a group called ‘Butterfly’. That was five decades ago. The
  //       group was the first in India to introduce Stainless Steel Pressure
  //       Cookers, Stainless Steel LPG Stoves and Unbreakable Stainless Steel Vacuum
  //       Flasks; first to acquire the ISO-9001 Certificate of Registration for
  //       manufacture and supply of LPG Stoves, Domestic Electric Food Mixers and
  //       Table-Top Wet Grinders. Over the years, under the enterprising leadership
  //       of Late V. Murugesa Chettiar (Founder – Chairman) and his five sons,
  //       Butterfly has grown to be a household name among millions in India.
  //     </p>
  //     <h3 className="text-align-center-sm text-primary font-size-14 my-40 font-bold">RISHABA INDUSTRIES LLP</h3>
  //     <p className="text-align-center-sm text-secondary font-size-14 line-height-30 mb-50">
  //       Part of the Butterfly Group, the family owned business was setup in April
  //       2019, to cater to the export market demand of non-home appliance products.
  //       The business is headed by three brothers from the family Mr. Vadivelan
  //       Kumaresan, Mr. Sivashanmugam Kumaresan and Mr. Gangadharam Kumaresan.
  //       <br /><br/>
  //       Currently the company manufactures Tableware and Kitchenware items and
  //       toys and exports products to the European Union.
  //       <br/><br/><br/>
  //       Some of the main
  //       reasons for Rishaba Industries LLP being very attractive for overseas
  //       buyers are:
  //       <br /><br/>
  //       - Backed by a 40-year-old family business
  //       <br /><br/>
  //       - One of the only manufacturers who provide Industrial Design,
  //       Engineering, Tool manufacturing and Production
  //       <br /><br/>
  //       - Located very near to port
  //       <br /><br/>
  //       - Very efficient manufacturingtimelines
  //       <br /><br/>
  //       - Higher flexibility
  //       <br /><br/>
  //       The facility is a “A” certified Amfori BSCI Industry, meaning a safer and
  //       worker friendly industry capable of producing for Global Sourcing Norms.
  //       By having the above certification, the company is eligible to produce for
  //       Big Buyers.
  //     </p>
  //   </div>
  //   <div className="d-flex row justify-content-center flex-wrap w-100">
  //     <img src="info_1.png" alt="alt info-1" className="p-1 w-30" />
  //     <img src="info_2.png" alt="alt info-2" className="p-1 w-30" />
  //     <img src="info_3.png" alt="alt info-3" className="p-1 w-30" />
  //   </div>
  //   <Clients />
  //   <Button className="mt-20 mb-50" name="CONTACT US" link="/contact" orientation="center"/>
  // </Layout>
)}

export default AboutPage
